.partners-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  transform: skewY(-2deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  transform-origin: top right;
}
.partners-striscia-sopra-partners {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #DAFD41;
}
.partners-partners {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners01 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners02 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners03 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners04 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners05 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners06 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners07 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners08 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners09 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners10 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners11 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners12 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners13 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners14 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners15 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners16 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners17 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners18 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners19 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners20 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners21 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners22 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners23 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners24 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners25 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners26 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners27 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners28 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners29 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners30 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners31 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partnersprova {
  width: 100%;
}
.partners-partners32 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-partners33 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.partners-container1 {
  flex: 0 0 auto;
  width: 100%;

  height: 100px;
  display: flex;
  align-items: flex-start;
}
.partners-partners-div1 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  transform: skewY(2deg);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: -40px;
}
.partners-partners-c1 {
  gap: 100px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-sixunits);

  align-items: flex-start;
  justify-content: center;
}

.partners-partners-c2 {
  gap: 100px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 280px;
  justify-content: center;
}
.partners-partner {
  width: 131px;
  object-fit: cover;
}
.partners-partner01 {
  width: 131px;
  object-fit: cover;
}
.partners-partner02 {
  width: 131px;
  object-fit: cover;
}
.partners-partner03 {
  width: 131px;
  object-fit: cover;
}
.partners-partner04 {
  width: 131px;
  object-fit: cover;
}
.partners-partner05 {
  width: 131px;
  object-fit: cover;
}
.partners-partner06 {
  width: 131px;
  object-fit: cover;
}
.partners-partner07 {
  width: 131px;
  object-fit: cover;
}
.partners-partner08 {
  width: 131px;
  object-fit: cover;
}
.partners-partner09 {
  width: 131px;
  object-fit: cover;
}
.partners-root-class-name {
  margin-top: -250px;
  margin-bottom: var(--dl-space-space-sixunits);
  background-color: #FBFBFF;
}
@media(max-width: 1200px) {
  .partners-partners-div1 {
    background-size: contain;
    background-position: center;
  }
  .partners-partners-c1 {
    align-self: flex-start;
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .partners-partners-c1 {
    justify-content: flex-start;
  }
  .partners-partners-div1 {
    background-size: contain;
    background-position: center;
 
  }
 
}

@media(max-width: 579px) {
  .partners-partners-c1 {
    justify-content: flex-start;
  }
  .partners-partners-div1 {
    background-size: contain;
    background-position: center;
    margin-top: 10px;
    margin-bottom: -90px
  }
  .partners-partner {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner01 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner02 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner03 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner04 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner05 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner06 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner07 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner08 {
    width: 131px;
    object-fit: cover;
  }
  .partners-partner09 {
    width: 131px;
    object-fit: cover;
  }
}
