
.popup-container3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-content3 {
  background-color: #fff;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.popup-close3 {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}

.popup-scrollable3 {
  max-height: 80vh;
  overflow-y: auto;
}

.popup-content-inner3 {
  padding: 20px;
}

.popup-title3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-number3 {
  font-size: 24px;
  font-weight: bold;
}

.popup-header3 {
  font-size: 18px;
  font-weight: bold;
}

.popup-text3 {
  margin-top: 10px;
  font-size: 16px;
}



.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #FBFBFF;
}
.scrollable {
  max-height: 300px;
  overflow: auto;
}
.footer-footer {
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  
}
.footer-indirizzo {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 156px;
  height: auto;
  display: flex;
  align-items: center;
}
.footer-image {
  width: 30px;
  height: auto;
  object-fit: cover;
}
.footer-text {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-text2 {
  color: #005884;
  font-size: 20px;
  font-family: Mongoose;
}
.footer-links-footer {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.footer-text4 {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-text5 {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-text6 {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-text7 {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-text8 {
  color: rgb(0, 88, 132);
  font-size: 20px;
  font-family: Mongoose;
}
.footer-icon-group {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-instagram {
  height: 2rem;
}
.footer-linkedin {
  height: 2rem;
}
.footer-whatsapp {
  height: 2rem;
}
.footer-root-class-name {
  margin-top: -60px;
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);

  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-instagram {
    display: none;
  }
  .footer-linkedin {
    display: none;
  }
  .footer-whatsapp {
    display: none;
  }
}

@media(max-width: 479px) {
  .footer-links-footer {
gap: 20px;

  }
  .footer-text4 {
    font-size: 14px;
  }
  .footer-text5 {
    font-size: 14px;
  }
  .footer-text6 {
    font-size: 14px;
  }
  .footer-text7 {
    font-size: 14px;
  }
  .footer-text8 {
    font-size: 14px;
  }
  .footer-indirizzo {
    margin-bottom: 20px;
  }
}
