.case-story-container {
  width: 100%;
  height: 461px;
  display: flex;
  z-index: 10;
  position: relative;
  transform: skewY(2deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  transform-origin: top right;
}

.case-story-striscia-sotto-about {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #dafd41;
}

.case-story-casestory {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory01 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory02 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory03 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory04 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory05 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory06 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory07 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory08 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory09 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory10 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory11 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory12 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory13 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory14 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory15 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory16 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory17 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory18 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory19 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory20 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory21 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory22 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory23 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory24 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory25 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory26 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory27 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory28 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory29 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory30 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory31 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory32 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory33 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory34 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory35 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory36 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory37 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory38 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-casestory39 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.case-story-about-us-div1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.case-story-root-class-name {
  height: 230px;
  background-color: #fbfbff;
}

@media (min-width: 2500px) {
  .case-story-root-class-name {
    height: 361px;
  }
}
/*
@media (max-width: 768px) {
 .case-story-container{
  height: 150px;
 }
}*/
