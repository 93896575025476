.video-component-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
 
}

.video-component-video-div1 {
  position: relative;
  width: 100%;
  height: 50vw;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: space-between;

}


.video-component-background-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.video-component-video-icona1 {
  width: 100px;
  align-self: flex-end;
  object-fit: cover;
  margin-bottom: -90px;
  z-index: 10;
  margin-left: 70px;
  display: none;
}

.video-component-video-icona2 {
  width: 50px;
  margin-top: -30px;
  object-fit: cover;
  margin-right: 10px;
  z-index: 10;
  display: none;
}

.video-component-root-class-name {
  align-self: center;
  margin-top: -1.8vw;
  margin-bottom: -2.5vw;
  position: relative; /* Needed for z-index */

}
.videoServizi {}

@media(max-width: 1200px) {
  .videoServizi {
    width: 80vw;
  }
}

@media(max-width: 900px) {
  .video-component-video-div1 {
    width: 90%;
    height: 50vw;
  }
}

@media(max-width: 900px) {
  .logoDominiHero {
    margin-top: -100px;
  }
  .video-component-video-icona1 {
    width: 100px;
    align-self: flex-end;
    object-fit: cover;
    margin-bottom: -90px;
    z-index: 10;
    margin-left: 0px;
  }
  .video-component-container {
    margin-bottom: 50px;
    margin-top: 40px;
  }
  .video-component-video-icona2 {
    width: 50px;
    margin-top: -30px;
    object-fit: cover;
    margin-right: -20px;
    z-index: 10;
  }
}

@media(min-width: 2500px) {
  .logoDominiHero {
    margin-top: -430px;
  }

}
@media(min-width: 901px) {
  .video-component-video-icona2 {
    display: none !important;
  }
  .video-component-video-icona1 {
    display: none !important;
  }
}