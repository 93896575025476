.servizi-container {
  width: 100%;
  height: 1030px;
  display: flex;
  position: relative;
  transform: skewY(1deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #005884;
  transform-origin: top right;
}

.no-scroll {
  overflow: hidden;
}

.zoom-image {
  transition: transform 0.7s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zoom-wrapper {
  overflow: hidden;
  display: inline-block;
  border-radius: 8px;
  position: relative;
}

@keyframes popupOpen {
  0% {
    opacity: 0;
    transform: scale(0.9) rotate(-3deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(-3deg);
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: popupOpen 0.3s ease-out;
  /* Add this line */
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.popup-video {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.zoom-wrapper:hover .zoom-image {
  transform: scale(1.2);
}

.zoom-wrapper:hover .image-overlay {
  opacity: 1;
}

.play-button {
  font-size: 2rem;
  color: white;
}

.zoom-image:hover {
  transform: scale(1.2);
}

.servizi-striscia-sopra-servizi {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #dafd41;
}

.servizi-servizi {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  gap: 20px;
  flex-direction: row;
  overflow: hidden;
}

.servizi-servizi01 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi02 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi03 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi04 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi05 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi06 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi07 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi08 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi09 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi10 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi11 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi12 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi13 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi14 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi15 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi16 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi17 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi18 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi19 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi20 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi21 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi22 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi23 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi24 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi25 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi26 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi27 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi28 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi29 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi30 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi31 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi32 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi33 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi34 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi35 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi36 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi37 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi38 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi39 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi40 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi41 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi42 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi43 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi44 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi45 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi46 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi47 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi48 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi49 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi50 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi51 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi52 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi53 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi54 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi55 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi56 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi57 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi58 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi59 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi60 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi61 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-servizi62 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.servizi-about-us-div1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  transform: skewY(-1deg);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  background-size: auto;
  justify-content: center;
  background-image: url("/playground_assets/sfondoservizi-1600h.png");
  background-repeat: no-repeat;
  background-position: center;
}

.servizi-about-child1 {
  flex: 0 0 auto;
  width: 76%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  margin-top: -130px;
}

.servizi-c1 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.servizi-text126 {
  color: rgb(251, 251, 255);
  font-size: 178px;
  font-family: Mongoose;
  line-height: 1.2;
}

.servizi-image {
  width: 30px;
  object-fit: cover;
}

.servizi-image1 {
  width: 285px;
  object-fit: cover;
}

.servizi-c2 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.servizi-text127 {
  color: rgb(251, 251, 255);
  font-size: 178px;
  font-family: Mongoose;
  line-height: 1.2;
  white-space: nowrap;
}

.servizi-image2 {
  width: 30px;
  object-fit: cover;
}

.servizi-image3 {
  width: 285px;
  object-fit: cover;
}

.servizi-c3 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.servizi-text128 {
  color: rgb(251, 251, 255);
  font-size: 178px;
  font-family: Mongoose;
  line-height: 1.2;
  white-space: nowrap;
}

.servizi-image4 {
  width: 30px;
  object-fit: cover;
}

.servizi-image5 {
  width: 285px;
  object-fit: cover;
}

@media (max-width: 1920px) {
  .servizi-text126 {
    font-size: 148px;
  }

  .servizi-text127 {
    font-size: 148px;
  }

  .servizi-text128 {
    font-size: 148px;
  }
}

@media (max-width: 1550px) {
  .servizi-about-us-div1 {
    background-size: contain;
    background-position: center;
  }

  .servizi-about-child1 {
    gap: 30px;
  }

  .servizi-text126 {
    font-size: 100px;
  }

  .servizi-text127 {
    font-size: 100px;
  }

  .servizi-text128 {
    font-size: 100px;
  }
}

@media (max-width: 1210px) {
  .servizi-c1 {
    flex-direction: column;
  }

  .servizi-text126 {
    text-align: center;
  }

  .servizi-c2 {
    flex-direction: column;
  }

  .servizi-text127 {
    text-align: center;
  }

  .servizi-c3 {
    flex-direction: column;
  }

  .servizi-text128 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .servizi-about-child1 {
    gap: 30px;
  }

  .servizi-c1 {
    flex-direction: column;
  }

  .servizi-text126 {
    font-size: 70px;
    text-align: center;
  }

  .servizi-c2 {
    flex-direction: column;
  }

  .servizi-text127 {
    font-size: 70px;
    text-align: center;
  }

  .servizi-c3 {
    flex-direction: column;
  }

  .servizi-text128 {
    font-size: 70px;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .servizi-about-child1 {
    width: 90%;
  }

  .servizi-text126 {
    font-size: 55px;
  }

  .servizi-text127 {
    font-size: 55px;
  }

  .servizi-text128 {
    font-size: 55px;
    text-align: center;
  }
}

@media (max-width: 1211px) {
  .servizi-container {
    height: 1400px;
  }
  .servizi-about-child1 {
    margin-bottom: 140px;
  }
}
@media (max-width: 450px) {
  .servizi-container {
    margin-top: -25vw;
  }
}
