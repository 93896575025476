.image-slider-container {
  width: 100%;
  height: 1207px;
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;
}

.image-slider-slide {
  gap: 120px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}

.image-slider-slide-div1 {
  flex: 0 0 auto;
  width: 550px;
  height: 344px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/playground_assets/screen1-700h.png");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}

.image-slider-div-c1 {
  width: 100px;
  height: 144px;
  display: flex;
  align-self: flex-start;
  margin-top: -90px;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}

.image-slider-text {
  color: rgb(0, 88, 132);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: 163px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.image-slider-text04 {
  color: rgb(0, 88, 132);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: 163px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.image-slider-ellisse {
  flex: 0 0 auto;
  width: 87px;
  height: 87px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  margin-left: -55px;
  background-size: cover;
  background-image: url("/playground_assets/ellisse%2027-200h.png");
  background-position: center;
}

.image-slider-div1c2 {
  flex: 0 0 auto;
  width: 350px;
  height: 73px;
  display: flex;
  transform: rotate(-6deg);
  align-self: flex-end;
  align-items: center;
  margin-right: -30px;
  border-radius: 5px;
  margin-bottom: -65px;
  justify-content: center;
  background-color: #8815ac;
}

.image-slider-text07 {
  color: rgb(218, 255, 65);
  font-size: 80px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 300;
}

.image-slider-slide-div2 {
  flex: 0 0 auto;
  width: 950px;
  height: 644px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/playground_assets/screen2-700h.png");
  background-repeat: no-repeat;
  background-position: center;
}

.image-slider-div-c11 {
  width: 100px;
  height: 144px;
  display: flex;
  align-self: flex-start;
  margin-top: -90px;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}

.image-slider-text08 {
  color: rgb(0, 88, 132);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: 163px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.image-slider-text12 {
  color: rgb(0, 88, 132);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: 163px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.image-slider-ellisse1 {
  flex: 0 0 auto;
  width: 87px;
  height: 87px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  margin-left: -55px;
  background-size: cover;
  background-image: url("/playground_assets/ellisse%2027-200h.png");
  background-position: center;
}

.image-slider-div1c21 {
  flex: 0 0 auto;
  width: 600px;
  height: 103px;
  display: flex;
  transform: rotate(-6deg);
  align-self: flex-end;
  align-items: center;
  margin-right: -30px;
  border-radius: 5px;
  margin-bottom: -65px;
  justify-content: center;
  background-color: #8815ac;
}

.image-slider-text15 {
  color: rgb(218, 255, 65);
  font-size: 80px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 300;
}

.image-slider-root-class-name {
  width: 100%;
  height: var(--dl-size-size-maxwidth);
  align-self: center;
}

.image-slider-root-class-name1 {
  margin-top: -200px;
}

@media (max-width: 768px) {
  /*.image-slider-slide {
    flex-direction: column;
    align-items: center;
    margin-left: 0px !important;
    gap: 180px;
  }*/
  .image-slider-slide-div1 {
    width: 90%;
    height: 70vw;
  }
  /*.image-slider-container {
    height: 2727px;
  }*/
  .image-slider-div1c2 {
    margin-right: 20px !important;
    width: 240px !important;
    height: 70px !important;
    margin-top: -50px !important;
  }
  .image-slider-text07 {
    font-size: 50px;
  }
  .image-slider-text {
    margin-top: 105px;
    font-size: 100px;
  }
  .image-slider-ellisse {
    width: 50px;
    height: 50px;
    margin-left: -172px;
  }
}

@keyframes popupOpen {
  0% {
    opacity: 0;
    transform: scale(0.9) rotate(-3deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(-3deg);
  }
}

.popup-container2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 44;

  padding: 5%;
}

.popcontentrotation {
  transform: rotate(3deg);
}

.popup-content2 {
  position: relative;
  background-color: #8815ac;
  padding: 200px;
  border-radius: 5px;
  transform: rotate(-3deg);
  border-radius: 20px;
  border-color: #daff41;
  border-width: 4px;
  animation: popupOpen 0.3s ease-out;
  /* Add this line */
}

.popup-close2 {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  padding: 10px;
  cursor: pointer;

  color: #daff41;
  font-size: 2;
}

.titlepopupdiv {
  display: flex;
  flex-direction: row;
  justify-content: left;

  align-items: center;
  align-content: center;
  gap: 20px;
}

.popup-textH1 {
  font-family: "Mongoose", sans-serif;
  color: #daff41;
  font-size: 5.2rem;
  font-weight: 800;
}

.popup-textNumber {
  font-family: "Mongoose", sans-serif;
  color: white;
  font-size: 3.2rem;
  font-weight: 800;
}

.popup-textP {
  font-family: "Mongoose", sans-serif;
  color: white;
  font-size: 2rem;
}
@media (max-width: 1170px) {
  .popup-content2 {
    padding: 100px;
  }
}

@media (max-width: 945px) {
  .popup-content2 {
    padding: 50px;
  }
}

@media (max-width: 768px) {
  .image-slider-slide-div2 {
    width: 500px;
    height: 500px;
  }

  .image-slider-div1c2 {
    width: 140px;
    height: 100px;
    margin-left: -30px;
  }

  .image-slider-div1c21 {
    width: 140px;
    height: 100px;
    margin-left: -30px;
  }
}

@media (max-width: 1920px) {
  .image-slider-slide {
    margin-left: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
  }
}

@media (max-width: 550px) {
  .image-slider-slide {
    gap: 80px;
  }
  .case-popup .popup-textP {
    font-size: 1.7rem;
  }
  .service-popup .popup-textP {
    font-size: 1.7em;
  }

  .popup-content2 {
    min-width: 120%;
  }
}

@media (max-width: 451px) {
  .image-slider-root-class-name1 {
    margin-top: -250px;
  }
}
@media (max-width: 375px) {
  .case-popup .popup-textP {
    font-size: 1.6rem;
  }
  .popup-content2 {
    padding: 20px 30px;
  }
  .popup-textH1 {
    font-size: 3.2rem;
  }
}
