@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.hero1-text-right-hero,
.hero1-text-right-hero1,
.hero1-text-right-hero2 {
  animation: floating 3s ease-in-out infinite;
}

.hero1-text-right-hero {
  animation-delay: 0s;
}

.hero1-text-right-hero1 {
  animation-delay: 0.4s;
}

.hero1-text-right-hero2 {
  animation-delay: 0.8s;
}






.hero1-container {
  width: 100%;
  height: 100%;

}

.hero1-hero-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  transform: skewY(2deg);
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}

.hero1-contenuto-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
    background-size: auto;
  background-image: url("/playground_assets/provalogo.png");
  background-position: center;
}
.logo-animated-container {
  z-index: 1;
}
.hero1-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  transform: skewY(-2deg);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.hero1-logo-icone-social {
  gap: 20px;
  flex: 0 0 auto;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hero1-logo {
  height: 4rem;
  margin-right: var(--dl-space-space-threeunits);
}

.hero1-instagram {
  height: 2rem;
}

.hero1-linkedin {
  height: 2rem;
}

.hero1-whatsapp {
  height: 2rem;
}

.hero1-desktop-menu {
  display: none;
}

.hero1-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.hero1-text001 {
  margin-left: var(--dl-space-space-twounits);
}

.hero1-text002 {
  margin-left: var(--dl-space-space-twounits);
}

.hero1-text003 {
  margin-left: var(--dl-space-space-twounits);
}

.hero1-text004 {
  margin-left: var(--dl-space-space-twounits);
}

.hero1-btn-group {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.hero1-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
}

.hero1-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero1-menu-icon {
  width: 50px;
  object-fit: cover;
}

.hero1-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/playground_assets/home-1500h.png");
  background-repeat: no-repeat;
  background-position: center;

}

.hero1-mobile-menu--on-top {
  position: relative;
}

.hero1-mobile-menu--on-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.hero1-nav1 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.hero1-container1 {
  width: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
}

.hero1-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero1-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.hero1-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.hero1-text005 {
  color: rgb(255, 255, 255);
  font-size: 46px;
  font-family: Mongoose;
  margin-bottom: var(--dl-space-space-unit);
}

.hero1-text006 {
  color: #DAFF41;
  font-size: 18vh;
  font-family: Mongoose;
}

.hero1-text007 {
  color: #DAFF41;
  font-size: 18vh;
  font-family: Mongoose;
}

.hero1-text008 {
  color: #DAFF41;
  font-size: 18vh;
  font-family: Mongoose;
}

.hero1-text009 {
  color: #DAFF41;
  font-size: 18vh;
  font-family: Mongoose;
  margin-bottom: var(--dl-space-space-unit);
}

.hero1-container2 {
  gap: 30px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: -20px;
  align-items: center;
  flex-direction: row;
}

.hero1-instagram1 {
  width: 33px;
  object-fit: cover;
}

.hero1-instagram2 {
  width: 33px;
  object-fit: cover;
}

.hero1-instagram3 {
  width: 33px;
  object-fit: cover;
}

.hero1-hero-h-div {
  gap: 80px;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  transform: skewY(-2deg);
  align-items: flex-start;
  justify-content: flex-start;
}

.hero1-heroh-part1 {
  flex: 0 0 auto;
  width: 40%;
  height: auto;
  display: flex;
  margin-top: 73px;
  align-items: flex-start;
  margin-left: 41px;
  flex-direction: column;
}

.hero1-text010 {
  color: rgb(136, 21, 172);
  z-index: 10;
  font-size: 250px;
  font-family: Mongoose;
  line-height: 0.9;
  white-space: nowrap;
}

.hero1-container3 {
  width: 532px;
  height: 45px;
  display: flex;
  margin-top: -80px;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: #DAFF41;
}

.hero1-text011 {
  color: rgb(136, 21, 172);
  font-size: 250px;
  align-self: flex-start;
  font-family: Mongoose;
  line-height: 0.8;
}

.hero1-text012 {
  color: #9D8DF1;
  font-size: 25px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: Inter;
  font-weight: 700;
  line-height: 0.9;
  margin-bottom: var(--dl-space-space-sixunits);
}

.hero1-heroh-part2 {
  gap: 50px;
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hero1-text-right-hero {
  gap: 40px;
  width: auto;
  height: 213px;
  display: flex;
  align-items: flex-start;
}

.hero1-text015 {
  font-size: 101px;
  transform: rotate(-20deg);
  font-family: Mongoose;
}

.hero1-text016 {
  color: #8815ac;
}

.hero1-text017 {
  color: #9d8df1;
}

.hero1-text018 {
  font-size: 101px;
  transform: rotate(15deg);
  align-self: flex-end;
  font-family: Mongoose;
}

.hero1-text019 {
  color: #8815ac;
}

.hero1-text020 {
  color: #9d8df1;
}

.hero1-text-right-hero1 {
  gap: 40px;
  width: auto;
  height: 213px;
  display: flex;
  align-items: flex-start;
}

.hero1-text021 {
  font-size: 101px;
  transform: rotate(15deg);
  font-family: Mongoose;
}

.hero1-text022 {
  color: #8815ac;
}

.hero1-text023 {
  color: #9d8df1;
}

.hero1-text024 {
  font-size: 101px;
  transform: rotate(-15deg);
  align-self: flex-end;
  font-family: Mongoose;
}

.hero1-text025 {
  color: #8815ac;
}

.hero1-text026 {
  color: #9d8df1;
}

.hero1-text-right-hero2 {
  gap: 40px;
  width: auto;
  height: 213px;
  display: flex;
  align-items: flex-start;
}

.hero1-text027 {
  font-size: 101px;
  transform: rotate(-9deg);
  font-family: Mongoose;
}

.hero1-text028 {
  color: #8815ac;
}

.hero1-text029 {
  color: #9d8df1;
}

.hero1-text030 {
  font-size: 101px;
  transform: rotate(-8deg);
  align-self: flex-end;
  font-family: Mongoose;
}

.hero1-text031 {
  color: #8815ac;
}

.hero1-text032 {
  color: #9d8df1;
}

.hero1-striscia-sotto {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #DAFD41;
}

.hero1-show-reel {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel01 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel02 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel03 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel04 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel05 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel06 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel07 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel08 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel09 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel10 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel11 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel12 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel13 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel14 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel15 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel16 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel17 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel18 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel19 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel20 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel21 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel22 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel23 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel24 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel25 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel26 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel27 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel28 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel29 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel30 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel31 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel32 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-show-reel33 {
  color: #005884;
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}

.hero1-root-class-name {
  background-repeat: no-repeat;
}

@media(max-width: 3200px) {
  .hero1-desktop-menu {
    display: none;
  }

  .hero1-btn-group {
    display: none;
  }

  .hero1-burger-menu {
    display: flex;
  }
}

@media(max-width: 1120px) {
  .hero1-heroh-part2 {
    display: none;
  }
}

@media(max-width: 767px) {
  .hero1-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .hero1-text001 {
    margin-left: var(--dl-space-space-unit);
  }

  .hero1-text002 {
    margin-left: var(--dl-space-space-unit);
  }

  .hero1-text003 {
    margin-left: var(--dl-space-space-unit);
  }

  .hero1-text004 {
    margin-left: var(--dl-space-space-unit);
  }

  .hero1-nav2 {
    align-self: flex-start;
    margin-bottom: 0px;
  }

  .hero1-text005 {
    font-size: 36px;
  }

  .hero1-text006 {
    font-size: 110px;
  }

  .hero1-text007 {
    font-size: 110px;
  }

  .hero1-text008 {
    font-size: 110px;
  }

  .hero1-text009 {
    font-size: 110px;
  }

  .hero1-text010 {
    font-size: 140px;
  }

  .hero1-container3 {
    width: 318px;
    height: 35px;
    margin-top: -55px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .hero1-text011 {
    font-size: 140px;
  }

  .hero1-text012 {
    font-size: 20px;
  }
}

@media(max-width: 479px) {
  .hero1-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }

  .hero1-instagram {
    display: none;
  }

  .hero1-linkedin {
    display: none;
  }

  .hero1-whatsapp {
    display: none;
  }

  .hero1-mobile-menu {
    padding: 16px;
  }

  .hero1-heroh-part1 {
    width: 100%;
    margin-left: var(--dl-space-space-halfunit);
  }

  .hero1-text010 {
    width: 100%;
  }

  .hero1-text012 {
    width: auto;
    margin-right: var(--dl-space-space-unit);
  }

  .hero1-root-class-name {
    margin-top: 0px;
  }

}
@media(max-width: 1120px) {
  .logo-animated-container {
    display: none !important;
  }
}