.contattaci-container {
  width: 100%;
  height: 1060px;
  display: flex;
  position: relative;
  transform: skewY(1deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #8815AC;
  transform-origin: top right;
}
.contattaci-striscia-sopra-contattaci {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #DAFD41;
}
.contattaci-aboutus {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus01 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus02 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus03 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus04 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus05 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus06 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus07 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus08 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus09 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus10 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus11 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus12 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus13 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus14 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus15 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus16 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus17 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus18 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus19 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus20 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus21 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus22 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus23 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus24 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus25 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus26 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus27 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus28 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus29 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus30 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus31 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus32 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus33 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus34 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus35 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus36 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus37 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus38 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus39 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus40 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus41 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus42 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-aboutus43 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.contattaci-contattaci-div1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  transform: skewY(-1deg);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  background-size: auto;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
}
.contattaci-contattaci-c1 {
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: center;
}
.contattaci-text88 {
  color: rgb(251, 251, 255);
  font-size: 100px;
  text-align: center;
  font-family: Mongoose;
  line-height: 0.5;
}
.contattaci-text90 {
  color: #daff41;
}
.contattaci-text91 {
  font-family: Mongoose;
  line-height: 1.2;
}
.contattaci-contattaci-button {
  flex: 0 0 auto;
  width: 373px;
  height: 130px;
  display: flex;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  border-radius: 25px;
  justify-content: center;
  background-color: #DAFF41;
}
.contattaci-text94 {
  color: #8815AC;
  font-size: 60px;
  font-family: Mongoose;
}
.contattaci-root-class-name {
  margin-top: 150PX;
}
@media(max-width: 1200px) {
  .contattaci-contattaci-div1 {
    background-size: contain;
    background-position: center;
  }
}
@media(max-width: 767px) {
  .contattaci-text88 {
    font-size: 70px;
  }
  .contattaci-container {
    width: 100%;
    height: 870px;
    display: flex;
    position: relative;
    transform: skewY(1deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815AC;
    transform-origin: top right;
  }
}
@media(max-width: 479px) {
  .contattaci-contattaci-c1 {
    width: 90%;
  }
  .contattaci-text88 {
    font-size: 55px;
  }
}

@media(max-width: 479px) {
  .contattaci-contattaci-button {
    width: 80%;
    height: 40%;
  }

}
